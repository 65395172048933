/* global Turbo */
/**
 * Does a get request using the fetch api
 *
 * @param {string} path - the path to the resource
 * @return {Promise} - a promise that resolves to the response
 * @example
 *  get('/api/v1/users').then(response => response.json())
 */
export function get(path) {
  return fetch(path, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
  })
}

export function getStream(path) {
  return fetch(path, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
      Accept: 'text/vnd.turbo-stream.html',
    },
  })
    .then((r) => r.text())
    .then((html) => Turbo.renderStreamMessage(html))
}

/**
 * Does a a put request using the fetch api
 *
 * @param {string} path - the path to the resource
 * @param {Object} body - the body of the request
 * @returns {Promise} - a promise that resolves to the response
 * @example
 *  put('/api/v1/users', { name: 'John' })
 */
export function put(path, body) {
  return fetch(path, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
  })
}

/**
 * Does a post request using the fetch api
 *
 * @param {string} path
 * @param {Object} body
 * @returns
 * @example
 *  post('/api/v1/users', { name: 'John' })
 */
export function post(path, body) {
  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
    },
  })
}

export function postStream(path, body) {
  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'X-CSRF-Token': getCsrfToken(),
      'Content-Type': 'application/json',
      Accept: 'text/vnd.turbo-stream.html',
    },
  })
}

/**
 * Does a delete request using the fetch api
 *
 * @param {string} path - the path to the resource
 * @returns
 * @example
 *  destroy('/api/v1/users/1')
 */
export function destroy(path) {
  return fetch(path, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

/**
 * A utility function to get the csrf token from the meta tag
 *
 * @returns {string} - the csrf token
 * @example
 *  getCsrfToken()
 */
function getCsrfToken() {
  return document.querySelector('meta[name="csrf-token"]').content
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  toggle({ target }) {
    if (!this.hasInputTarget || this.inputTarget === target) return

    this.inputTarget.checked = !this.inputTarget.checked

    this.#emitChange()
  }

  check({ target }) {
    if (!this.hasInputTarget || this.inputTarget === target) return

    this.inputTarget.checked = true

    this.#emitChange()
  }

  uncheck({ target }) {
    if (!this.hasInputTarget || this.inputTarget === target) return

    this.inputTarget.checked = false

    this.#emitChange()
  }

  #emitChange() {
    this.inputTarget.dispatchEvent(new Event('input'), { bubbles: true })
  }
}

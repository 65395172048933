import React, { useEffect, useRef } from 'preact/compat'
import { h, Fragment } from 'preact'

export function AddressField({ address, addressChange, required }) {
  const controllerRef = useRef(null)
  const addressRefs = useRef({})

  const hiddenFields = ['street', 'city', 'state', 'zip', 'country', 'lat', 'lng']

  const addressChangeCallback = () => {
    const newAddress = {}

    newAddress.full = addressRefs.current.full?.value
    newAddress.street_2 = addressRefs.current.street_2?.value
    ;['street', 'city', 'state', 'zip', 'country', 'lat', 'lng'].forEach((name) => {
      newAddress[name] = addressRefs.current[name]?.value
    })

    addressChange(newAddress)
  }

  useEffect(() => {
    const controllerElement = controllerRef.current

    controllerElement?.addEventListener('address-field--component:input', addressChangeCallback)

    return () => {
      controllerElement?.removeEventListener('address-field--component:input', addressChangeCallback)
    }
  }, [addressChange])

  return (
    <div
      ref={controllerRef}
      class="address-field"
      data-controller="address-field--component"
      data-address-field--component-message-value="Please select an address from the dropdown before proceeding."
    >
      <div className="visible-fields">
        <input
          type="text"
          class="input input-border"
          placeholder="Type here"
          value={address.full}
          required={required}
          data-address-field--component-target="autocomplete"
          data-action="address-field--component#clearHiddenFields"
          ref={(el) => (addressRefs.current.full = el)}
        />
        <input
          type="text"
          class="input input-border"
          placeholder="(Optional) Apartment/Suite number, etc."
          value={address.street_2}
          ref={(el) => (addressRefs.current.street_2 = el)}
          onInput={addressChangeCallback}
        />
      </div>

      {hiddenFields.map((name) => (
        <input
          type="hidden"
          value={address[name]}
          data-address-field--component-target={name}
          ref={(el) => (addressRefs.current[name] = el)}
        />
      ))}
    </div>
  )
}

import { Controller } from '@hotwired/stimulus'
import React, { Fragment } from 'preact/compat'
import { h, render } from 'preact'
import { LeadCaptureForm } from '../components/lead_capture_form'
import { signal } from '@preact/signals'

export default class extends Controller {
  static values = { json: Object }
  static targets = ['form', 'container', 'phoneField']

  jsonSignal

  initialize() {
    this.jsonSignal = signal(this.jsonValue)

    this.update()
    this.containerTarget.innerHTML = ''
    this.#render()
  }

  update() {
    const formData = new FormData(this.formTarget)
    const baseKey = 'lead_capture_form[lead_capture_form_fields_attributes]'
    const fieldIndices = Array.from(
      new Set(
        Array.from(formData.keys())
          .filter((key) => key.startsWith(baseKey))
          .map((key) => key.match(/-?\d+/g)[0])
          .filter((index) => index),
      ),
    )

    const fieldsOrdered = fieldIndices.reduce((acc, index) => {
      const formKey = (name) => `${baseKey}[${index}][${name}]`
      const order = Number(formData.get(formKey('order')))
      const key = formData.get(formKey('key'))
      const label = formData.get(formKey('label'))
      let type = formData.get(formKey('type'))
      const required = key === 'name' || formData.getAll(formKey('required')).includes('1')

      if (formData.get(formKey('enter_as'))?.includes('phone')) {
        type = 'tel'
      }

      const destroyed = formData.getAll(formKey('_destroy')).includes('1')

      if (!destroyed) {
        acc[order] = { key, label, type, required }
      }

      return acc
    }, {})

    const fields = Object.keys(fieldsOrdered)
      .sort()
      .map((key) => fieldsOrdered[key])

    const colors = Object.fromEntries(
      ['primary_color', 'secondary_color', 'tertiary_color', 'quaternary_color', 'shadow_color'].map((color) => [
        color,
        formData.get(`lead_capture_form[lead_capture_form_theme_attributes][${color}]`),
      ]),
    )

    this.jsonValue = { ...this.jsonValue, fields, ...colors }
  }

  jsonValueChanged() {
    this.jsonSignal.value = this.jsonValue
  }

  #render() {
    const BoundPreview = this.Preview.bind(this)

    render(<BoundPreview />, this.containerTarget)
  }

  Preview() {
    const data = this.jsonSignal.value
    let style = `--primary-color: ${data.primary_color}; --secondary-color: ${data.secondary_color};`
    style += ` --tertiary-color: ${data.tertiary_color}; --quaternary-color: ${data.quaternary_color};`
    style += ` --shadow-color:${data.shadow_color}; --secondary-color-light: ${data.secondary_color}BF;`
    style += ` --btn-bg: var(--primary-color); --btn-hocus-bg: ${data.primary_color}E6;`
    style += ' --btn-text-color: var(--quaternary-color); --input-border-color: var(--secondary-color-light);'
    style += ' --input-border-focus-color: var(--primary-color); --toggle-bg-on: var(--primary-color);'
    style += ' --toggle-bg-off: var(--secondary-color-light); --toggle-handle-bg: var(--tertiary-color)'

    return (
      <div
        class="bg-[--tertiary-color] p-3 pointer-events-none space-y-3"
        style={`${style};  color: var(--secondary-color) !important;`}
      >
        <LeadCaptureForm leadCaptureForm={data.fields} />
        <div class="flex justify-end">
          <button type="button" class="btn">
            SUBMIT
          </button>
        </div>
      </div>
    )
  }
}
